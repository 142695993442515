#title { 
  border: 1px solid lightgray; 
  border-radius: 10px;
  margin-top: 10px;
  /* margin-left: 10px; */
  padding-bottom: 0px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.tox-notifications-container {display: none !important;}

/* .col-6{border: 1px solid lightgray;} */
* {
  /* border: 1px solid blue; */
}
html, body {
  height: 100%;
}

html {
  display: table;
  margin: auto;
}

body {
  display: table-cell;
  vertical-align: middle;
}

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('../public/nature2.svg'); */
  /* background-image: url('./assets/bg.svg'); */
  background: linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,.3)), url('./assets/bg.svg');
  background-repeat: space;
  /* transform: rotate(30deg); */
/* background-repeat: repeat-x, repeat-y;
background-position-y: -60px, 400px;
background-position-x: -150px, -270px; */

  /* background-size: 200px 200px; */
  background-blend-mode: lighten;
}
body::before{
  /* content: ""; */

  opacity: 0.3;
  /* background-image: url('./assets/bg.svg'); */
  /* background-blend-mode: lighten; */

  /* background-repeat: space; */
  /* background-color: white ; */
  /* background-color: rgba(0,0,0,0.25); */

}

.container{
  background-color: white ;
  /* border: 1px solid red; */
  border-radius: 30px;
}

.titleastags{
  border: 1px dashed lightgray;
  border-radius: 10px;
}

/* .titleastags > span{
  position: relative;
  border: 1px solid red;
  top: 10px;
  z-index: 0;
} */

.imageholder {
  /* display: flex; */
  /* text-align: center; */
  justify-content: center;
}
img {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}

/* .header, .footer {
  position: fixed ;
} */

.col-5, .col-6 {
  /* border: 1px solid green; */
}

/* button:hover,
button:focus {
    background: #0053ba;
}

button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
} */

.build {
  color: gray;
  margin-top: 20px;
  margin-bottom: 1em;
}

.build > span {
  color: red;
}

.footer {
  border-top: 1px dashed gray;
  /* border: 1px solid black; */
  /* background: lightgrey; */
  /* background: linear-gradient(180deg, rgba(199,199,199,0.5394490559895833) 0%, rgba(2,0,36,1) 100%);  */
}

.titleastags > .t5 {
  /* display: inline-flex; */
  position: relative;
  /* border: 1px solid red; */
  background-color: aliceblue;
  padding: 10px 10px;
  border-radius: 20px;
  top: 10px;
  z-index: 0;
  font-weight: bolder;
  float: right;
  font-size: small;

  /* offset-anchor: right bottom;
  offset-distance: 100%;  */
  /* inset: right bottom; */
}

.titleastags > .gpts {
  /* display: inline-flex; */
  position: relative;
  /* border: 1px solid red; */
  background-color:bisque;
  padding: 0.25em;
  top: 10px;
  z-index: 0;
  padding: 10px 10px;
  border-radius: 20px;
  font-weight: bolder;
float: inline-end;
font-size: small;

  /* offset-anchor: right bottom;
  offset-distance: 100%;  */
  /* inset: right bottom; */
}

.titleastags > .gpt3 {
  /* display: inline-flex; */
  margin-left: 0.5em;
  position: relative;
  /* border: 1px solid red; */
  background-color:chartreuse;
  padding: 10px 10px;
  border-radius: 20px;
  top: 10px;
  z-index: 0;
  font-weight: bolder;
float: right;
font-size: small;
  /* offset-anchor: right bottom;
  offset-distance: 100%;  */
  /* inset: right bottom; */
}




/* CSS */
.button-11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  color: #3D3D3D;
  background: #fff;
  border: none;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-11:focus {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.button-16 {
  background-color: #8EA7E9;
  border: 1px solid #8EA7E9;
  border-radius: 10px;
  color: #3c4043;
  cursor: pointer;
  /* font-family: arial,sans-serif; */
  /* font-size: 14px; */
  height: 36px;
  /* line-height: 27px; */
  min-width: 54px;
  padding: 0 16px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
  margin-top: 1em;
}

.button-16:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  color: #202124;
}

.button-16:focus {
  border-color: #7286D3;
  outline: none;
}

.button-green {
  background-color: #77dd77  ;
  border: 1px solid #8be28b ;
  border-radius: 10px;
  color: #3c4043;
  cursor: pointer;
  /* font-family: arial,sans-serif; */
  /* font-size: 14px; */
  height: 36px;
  /* line-height: 27px; */
  min-width: 54px;
  padding: 0 16px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
  margin-top: 1em;
}

.button-green:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
  color: #202124;
}

.button-green:focus {
  border-color: #7286D3;
  outline: none;
}

.beejicon{
  flex-grow: 1;  /* max-width: 120px; */
  /* margin: auto; */
  /* width: 100px; */
  /* margin-right: 0; */
  /* padding-right: 0; */
  margin-left: auto;
  height: 100px;
  /* border: 3px solid blue; */
  /* position: absolute; */
  /* align-self: flex-end; */
}
.beejtext{
  flex: 0 1 auto;
  /* align-self: center; */
  /* margin: auto; */
  /* justify-self: center; */
  position: absolute;
  /* left: 50%; */
  /* transform: translateX(-50%); */
}
.dummy{
  flex-grow: 1;
}
.header{
  /* background: #FDFBE7; */
  /* background: white; */
  /* background: linear-gradient( rgba(255,255,255,0.23) 0%, rgba(164,49,34, .85) 100%); */
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-wrap: nowrap;
  /* flex-grow: 1; */
  flex-direction: row;
  /* justify-content: space-between; */
  /* justify-content: space-around; */
  justify-content: center;
  align-items: center;
  margin:auto;
  margin-bottom: 1em;
}

.skeleton-image{
  display: inline-block;
  width: 350px;
  /*
    define as separate properties
  */
  --card-height: 340px;
  --card-padding:24px;
  --card-skeleton: linear-gradient(gray var(--card-height), transparent 0);

  --title-height: 32px;
  --title-width: 200px;
  --title-position: var(--card-padding) 180px;
  --title-skeleton: linear-gradient(white var(--title-height), transparent 0);

  --avatar-size: 32px;
  --avatar-position: var(--card-padding) var(--card-padding);
  --avatar-skeleton: radial-gradient(
    circle calc(var(--avatar-size) / 2), 
    white 99%, 
    transparent 0
  );

  /* 
    now we can break the background up 
    into individual shapes 
  */
  background-image: 
    var(--avatar-skeleton),
    var(--title-skeleton),
    var(--card-skeleton);

  background-size:
    var(--avatar-size),
    var(--title-width) var(--title-height),
    100% 100%;

  background-position:
    var(--avatar-position),
    var(--title-position),
    0 0;

}

.demo:empty {
  margin: auto;
  width: 200px;
  height: 200px; /* change height to see repeat-y behavior */
  
  background-image:
    radial-gradient( circle 100px at 100px 100px, lightgray 100%, transparent 0 );
    /* linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 ); */

  /* background-repeat: repeat-y; */

  background-size:
    400px 400px; /* circle */


  background-position:
    0 0; /* circle */
    /* 0 0, /* highlight */
 

  animation: shine 1s infinite;
}

.pageclip-form > textarea{
  /* padding: 5px; */
  /* margin: 5px; */
  width: 100%;
  border-width: 2px;
  /* border-radius: 10px; */
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  /* display: flexbox; */
  /* flex-direction: column; */
}

input {
  outline: none;
  width: 75%;
  /* padding: 5px; */
  /* margin: 5px; */
  /* border-radius: 40px; */
  border-bottom-width: 2px;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}

.info, .support {
  display: flexbox;
  flex-direction: column;
}
.donate {
  border-top: 1px solid lightgray;
  /* margin-top: 5px; */
  padding-top: 5px;
}

button{
  margin: 10px;
  /* padding: 10px; */
}
.pushable-g {
  /* background: hsl(340deg 100% 32%); */
  background: #77dd77;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front-g {
  display: block;
  padding: 4px 10px;
  border-radius: 12px;
  /* font-size: 1.25rem; */
  /* background: hsl(345deg 100% 47%); */
  background: #8be28b;
  color: white;
  transform: translateY(-6px);
}

.pushable-g:active .front-g {
  transform: translateY(-2px);
}

.pushable-g:focus:not(:focus-visible) {
outline: none;
}

.pushable-b {
  /* background: hsl(340deg 100% 32%); */
  background: #7286D3;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}
.front-b {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 12px;
  /* font-size: 1.25rem; */
  /* background: hsl(345deg 100% 47%); */
  background: #8EA7E9;
  color: white;
  transform: translateY(-6px);
}

.pushable-b:active .front-b {
  transform: translateY(-2px);
}

.pushable-b:focus:not(:focus-visible) {
outline: none;
}